<template>
    <div>
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Update the organization name"
            @show="resetEditNameModal"
            @hidden="resetEditNameModal"
            @ok="editOrganizationName"
            >
            <form ref="form" @submit.stop.prevent="handleSubmit" class="pl-4 pr-4 pt-4 pb-4">
                <b-form-group
                label="Organization name"
                label-for="name-input"
                invalid-feedback="Name is required"
                :state="nameState"
                >
                    <b-form-input
                        id="name-input"
                        v-model="name"
                        :state="nameState"
                        required
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div style="overflow-x: auto; margin-top: 80px;">
        <CreateOrganizationModal :modalShow1="modalShowInvite" v-model="modalShowInvite" @users-invited="updateOrganizationlist"></CreateOrganizationModal>
        <!-- <InviteUserModal :modalShow1="modalShowInvite" v-model="modalShowInvite" :inputField="inputField" @users-invited="UsersDataUpdate"></InviteUserModal> -->
        <div class="manage-users__container" style="width: fit-content; margin: auto; overflow-x: auto;">
            <div class="d-flex justify-content-between" style="margin-bottom: 32px;">
                <h4 class="manage-user__settings-link" role="button" @click="$router.push('/projects')"><span class="manage-user__settings-link--arrow"><img src="../../assets/icon_arrow_left.svg" alt=""></span> {{$t('projects.users_table.projects')}}</h4>
                <div class="d-flex flex-column">
                    <p class="mb-0" style="font-weight: 600;">{{activeUser.name}}</p>
                    <p class="mb-0" style="text-align: right;">{{activeUser.role}}</p>
                </div>
            </div>
            <div style="overflow-x:auto; padding-bottom: 60px; min-width: 40rem;">
                <div class="d-flex flex-row project__usersTable--header justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <h5 class="m-0">{{$t('Manage_organizations.organizations')}}</h5> 
                        <BaseBadge status="counter" :value="organizations.length" class="ml-2"/>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <b-form-input id="input-default" v-model="searchInput" placeholder="Search" style="width: fit-content;" class="height-40"></b-form-input>
                        <button @click="modalShowInvite = true" class="ml-2 button-accent500 button-borderRadius-8 height-40" style="padding-left: 12px; padding-right: 12px; font-weight: 500;">Aggiungi organizzazione</button>
                    </div>
                </div>
                <b-table-simple v-if="!loading" id="my-table" :per-page="perPage" :current-page="currentPage" class="project__usersTable" style="overflow-x:auto;">
                    <b-thead>
                        <b-tr class="topicModelingTable__table__head" style="line-height: 1.5;">
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width ">
                                {{$t('Manage_organizations.org_id')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">
                                {{$t('Manage_organizations.name')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">
                                {{$t('Manage_organizations.members')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width ">
                                {{$t('Manage_organizations.added_date')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width ">
                                {{$t('Manage_organizations.status')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium">
                                {{$t('Manage_organizations.edit_name')}}
                            </b-th>
                            <b-th class="align-middle topicModelingTable__table__heading dig-font-weight-medium">
                                {{$t('Manage_organizations.delete')}}
                            </b-th>
                            
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="organization in organizationsInTable" :key="organization.id">
                            <b-td class="align-middle project__usersTable--nameColumn">
                                {{organization.id}}
                            </b-td>
                            <b-td class="align-middle project__usersTable--nameColumn">
                                {{organization.name}}
                            </b-td>
                            <b-td class="align-middle">
                                {{organization.users.length}}
                            </b-td>
                            <b-td class="align-middle">
                               No 
                            </b-td>
                            <b-td class="align-middle ">
                                <BaseBadge :status="organization.users.length > 0 ? 'active' : 'pending'"/>
                            </b-td>
                            <b-td>
                                <b-button v-b-modal.modal-prevent-closing @click="setOrganizationId(organization.id)" @keydown.enter="setOrganizationId(organization.id)">{{$t('Manage_organizations.edit_button')}}</b-button>
                            </b-td>
                            <b-td>
                                <button type="button" class="qa__tab--buttonIcon-delete" title="Disable this user"></button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>   
                <b-skeleton-table
                    v-if="loading"
                    :rows="8"
                    :columns="6"
                    :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table> 
                <b-pagination
                    v-if="paginationIsVisible"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="justify-content-center flex-end"
                ></b-pagination>
            </div>  
        </div>
    </div>
    </div>
    
</template>

<script>
import BaseBadge from '../base/BaseBadge.vue'
import CreateOrganizationModal from './CreateOrganizationModal.vue'
import BaseActionBar from '../base/BaseActionBar.vue'
import AssignProjectModal from './AssignProjectModal.vue'
import axios from 'axios'

export default {
    name: "TheManageOrganizations",

    components:{
        BaseBadge,
        CreateOrganizationModal,
        BaseActionBar,
        AssignProjectModal
    },

    data(){
        return{
            selectedOrganizationNameId: null,
            name: "",
            activeUser: null,
            paginationIsVisible: true,
            organizations: [],
            loading: true,
            searchInput: "",
            perPage: 10,
            currentPage: 1,
            modalShowInvite: false,
        }
    },

    computed: {
        rows() {
            // Returns the total number of users
            return this.organizations.length
        },

        organizationsInTable() {
            // Calculate the starting and ending indices for the current page
            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Filter users based on the search input
            let filteredOrganizations = this.organizations.filter(org => org.name.toLowerCase().startsWith(this.searchInput.toLowerCase()));

            // Update the pagination visibility based on the number of filtered users
            if(filteredOrganizations.length <= this.perPage)
                this.paginationIsVisible = false;
            else
                this.paginationIsVisible = true;

            // Return the users to be displayed on the current page
            return filteredOrganizations.slice(start, end);
        },

    },

    methods: {
        resetEditNameModal(){
            this.name = ""
        },
        setOrganizationId(id){
            this.selectedOrganizationNameId = id  
        },

        getActiveUser(){
            // Get the active user from the Vuex store
            this.activeUser = this.$store.getters.activeUser;
        },

        async deleteOrganization(){
            try{
                const response = await axios.delete
            } catch{

            }
        },

        async editOrganizationName(){
            try {
                const response = await axios.post('organizations/' + this.selectedOrganizationNameId, {"name": this.name})
                await this.updateOrganizationlist()
                this.resetEditNameModal();
            } catch (error) {
                alert(error)
            }
        },

        async updateOrganizationlist(){
            this.loading = true;
            await this.getOrganizationsList();
        },

        async getOrganizationsList(){
            try {
                const orgListResponse = await axios.get('organizations')
                const orgList = orgListResponse.data.data

                const promises = orgList.map(
                    async org => {
                        const usersResponse = await axios.get(`organization/${org.id}/users`)
                        const users = usersResponse.data.data
                        console.log(users)
                        return {
                            id: org.id,
                            name: org.name,
                            users: users
                        }
                    }
                )
                const combinedList = await Promise.all(promises)
                // Step 3: Set combinedList to Vue data property
                this.organizations = combinedList
                console.log("Combined list " + combinedList)
                console.log(this.organizations)
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        },

        async authorizeUser() {
            let token;
            // Get the JWT token from localStorage, sessionStorage, or cookies
            if (localStorage || sessionStorage) {
                token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
            } else {
                token = cookies.getCookie("jwt");
            }
            // Set the default authorization header for Axios requests
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },

        OrganizationsDataUpdate(){
            this.getOrganizationsList();
        },
    },

    provide() {
        return {
            modalShow: this.modalShow
        };
    },

    async created() {
        await this.authorizeUser();
        this.getActiveUser();
        await this.getOrganizationsList();
    },

    async mounted() {
        // await this.getRolesList();
        // await this.getUsers();
    }
}
</script>

<style lang="scss" scoped>

@import '../../styles/_colors.scss';

    .page-link{
        display: none !important;
    }

    select{
        font-size: 14px;
        color: #667085;
        border: none;
    }

    table{
        margin-bottom: 32px;
        color: #212529;
        border-collapse: separate;
        border: 1px solid #DADADA;
        border-radius: 0 0 10px 10px;
        border-spacing: 0;
        border-top: none;
        thead th{
            border-bottom: none;
        }
    }

    th{
        background-color: #F9FAFB;
    }

    tr{
        color: #667085;
    }
    .project__container{
        text-align: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10rem;
    }

    .project{
        &__usersTable{
            width: auto;
            &--td-Width{
                min-width:11.5rem;
                max-width: 15rem;
            }
            &--nameColumn{
                color: #101828;
            }
            &--header{
                height: 84px;
                align-items: center;
                padding: 16px 22px;
                border: 1px solid #DADADA;
                border-bottom: none;
                border-radius: 10px 10px 0 0;
            }
        }
    }

    .base__Badge-Counter{
        padding: 2px 8px;
        width: 38px;
        height: 22px;
        /* align-items: center; */
        text-align: center;
        border-radius: 16px;
        color: #6941C6;
        background: #F9F5FF;
        font-weight: 500;
    }
    .qa__tab--buttonIcon-delete{
        opacity: 0.5;
        transition: 0.2s ease;
        &:hover{
            transition: 0.2s ease;
            opacity: 1;
        }
    }

    .manage-user{
        &__settings-link{
            
            &--arrow{
                margin-right: 1rem;
                transition: all 0.15s ease;

            }
            transition: all 0.15s ease;
            &:hover{
                transition: all 0.15s ease;
                color: var(accent500);
                .manage-user__settings-link--arrow{
                    margin-right: 0.6rem;
                    transition: all 0.15s ease;
                    filter: opacity(0.6);
                }
            }
        }
    }
</style>