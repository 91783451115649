<template lang="">
    <div>
        <TheManageOrganizations> </TheManageOrganizations>
    </div>
</template>
<script>
import TheManageOrganizations from '../components/aiknowu/TheManageOrganizations.vue';

export default {
    components: {
        TheManageOrganizations
    }    
}
</script>
<style lang="">
    
</style>