<template>
    <b-modal class="modal-dialog-centered modal-lg" ref="myModal" v-model="modalShow" @hidden="onHidden" @hide="onHide" size="400" hide-footer hide-header>
    <div class="d-flex flex-column inviteUserModal" style="padding: 24px;">
        <div class="d-flex flex-row justify-content-between mb-4 align-items-start">                
            <BaseInviteIcon/>
            <button class="button-no-style" @click="modalShow=false"><BaseCloseIcon></BaseCloseIcon></button>
        </div>
        <div class="mb-2 supportingText">
            <span class="h4">{{$t('Manage_organizations.create_org.title')}}</span>
            <p class="mt-1">{{$t('Manage_organizations.create_org.description')}}</p>
        </div>
        <div v-if="!loading" class="d-flex flex-column mt-2">
            <label for="">{{$t('Manage_organizations.create_org.org_name')}}</label>
            <input placeholder="Enter name" type="email" class="font-16 primary-border-button height-44 white-input primary-padding-input" v-model="organization_name">
        </div>
        <div v-if="!loading" class="d-flex flex-column mt-2">
            <label for="">{{$t('Manage_organizations.create_org.admin_email')}}</label>
            <input placeholder="Enter email" type="email" class="font-16 primary-border-button height-44 white-input primary-padding-input" v-model="email">
        </div>
        <div v-if="loading" style="height: 100%; width: 100%; padding: 2rem;" class="d-flex align-items-center justify-content-center">
            <div class="spinner-border d-flex justify-content-center" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center align-center mt-5" v-if="!loading">
            <button @click="modalShow=false" class="primary-border-button button-white height-44" style="width: 50%; margin-right: 6px; font-size: 16px;">{{$t('InviteUserModal.invite_collab_cancel')}}</button>
            <button class="button-accent500 primary-border-button height-44" style="width: 50%; margin-left: 6px; font-size: 16px;" @click="createOrganization">{{$t('InviteUserModal.invite_collab_invite')}}</button>
        </div>
    </div>
    </b-modal>
</template>

<script>
import BaseInviteIcon  from "../base/BaseInviteIcon.vue"
import BaseCloseIcon from "../base/BaseCloseIcon.vue"
import axios from "axios";

export default {
    components: {
        BaseInviteIcon,
        BaseCloseIcon
    },
    name: "CreateOrganizationModal",
    props: {
        modalShow1: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            modalShow: this.modalShow1,
            loading: false,
            alert_error : "",
            email: "",
            organization_name: ""
        };
    },

    watch: {
        modalShow1(newValue) {
            this.modalShow = newValue;
        }
    },

    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        onHidden() {
            this.loading = false;
            this.$emit('input', false);
            this.inputFields = [{ value: "", label: "Email address" }];
        },

        onHide(){
        },

        async createOrganization(){
            try {
                let orgResponse = await axios.put('/organizations', {"name": this.organization_name});
                orgResponse = orgResponse.data.data;
                const userResponse = await axios.put('/user', {"org_id": orgResponse.id, users: [{"email": this.email, "role": "admin"}]});
                this.$emit('users-invited')
                this.onHidden();
            } catch (error) {
                alert(error);
            }

        }
    }
}
</script>

<style lang="scss">
.modal-400{
    width: 400px !important;
}
</style>